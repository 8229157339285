/*
 *
 */

// Vendors
import React from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import Helmet from 'react-helmet';
import { trackCustomEvent } from 'gatsby-plugin-google-analytics';

// Components
import Section from 'components/section';
import Button from 'components/button';
import Text from 'components/text';
import RegionList from 'components/region-list';

// Data
import regions from 'data/regions.json';

/*
 *
 */
export default function List () {

	const intl = useIntl();

	return (
		<React.Fragment>
			<Helmet>
				<title>{intl.formatMessage({ id: 'page.list.meta_title' })}</title>
				<meta name="description" content={intl.formatMessage({ id: 'page.list.meta_desc' })} />
				<meta property="og:image" content={intl.formatMessage({ id: 'global.meta.og_image.list' })} />
				<meta property="og:url" content={intl.formatMessage({ id: 'global.meta.og_url.list' })} />
			</Helmet>
			<Section>
				<Text>
					<h2>
						{intl.formatMessage({ id: 'page.list.section.regionlist.title' })}
					</h2>
					<div />
				</Text>
				<RegionList regions={regions} />
				<div />
			</Section>
			<Section>
				<Text>
					<h3>{intl.formatMessage({ id: 'page.list.section.add_restaurant.title' })}</h3>
					<p>
						{intl.formatMessage({ id: 'page.list.section.add_restaurant.p_1' })}
					</p>
					<p>
						<Button 
							href={intl.formatMessage({ id: 'global.link.add_restaurant' })}
							onClick={e => {
					        // Lets track that custom click
					        trackCustomEvent({
					          // string - required - The object that was interacted with (e.g.video)
					          category: "Button",
					          // string - required - Type of interaction (e.g. 'play')
					          action: "Add Restaurant",
					          // string - optional - Useful for categorizing events (e.g. 'Spring Campaign')
					          label: "List - Section"
					        })
					      }}>
							{intl.formatMessage({ id: 'page.home.section.4.add_restaurant' })}
						</Button>
					</p>
					<h3>{intl.formatMessage({ id: 'page.list.section.partners.title' })}</h3>
					<p>
						<FormattedMessage
							id="page.list.section.partners.p_1"
							values={{
								a: (...chunks) => (
									<a href="mailto:hello@aide-aux-restaurateurs.be">
										{chunks}
									</a>
								)
							}}
						/>
					</p> 
				</Text>
			</Section>
		</React.Fragment>
	);
}