/*
 *
 */
import React from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import styled, { css } from 'styled-components';
import { Link } from 'gatsby';
import { DEFAULT_LANG } from 'i18n';

const Root = styled.ul`
	margin:0 0 2rem;
	padding:0;
	list-style:none;

	display:flex;
	flex-wrap:wrap;

	&:last-child {
		margin-bottom: -2rem;
	}
`;

const RegionItem = styled.li`
	width:100%;
	padding:0 1rem 1rem;
	box-sizing:border-box;

	@media (${props => props.theme.media.tablet}) {
		width:33.33%;
		padding:0 1rem 2rem;
	}
`;

const Subtitle = styled.div`
	display:inline-block;

	color:#1b83a4;
	font-weight:200;
	font-size:1.4rem;
	margin-top:0.66em;

	border-bottom:1px solid transparent;
	transition:all .3s cubic-bezier(0.23,1,0.32,1);
`;

const RegionLink = styled.a`
	display:block;

	padding:2.45rem 3rem;
	background:#fff;
	border:1px solid #eaeaea;
	border-radius:.35rem;

	text-align:center;
	text-decoration:none;
	box-shadow:0 0 2rem 0 rgba(0,0,0,.05);

	transition:all .3s cubic-bezier(0.23,1,0.32,1);

	&:hover {
		border-color:#1b83a4;
		box-shadow:0 0 1rem 0 rgba(0,0,0,.01);
	}

	${({ type }) => {
		switch (type) {
			case 'comingsoon':
				return css`
					pointer-events: none;
  					cursor: default;
  					${Subtitle} {
						display:none;
					}
				`;
			case 'live':
			default:
				return css`
					box-shadow:0 0 2rem 0 rgba(0,0,0,.05);
						&:hover {
							border-color:#1b83a4;
							box-shadow:0 0 1rem 0 rgba(0,0,0,.01);
						}
						&:hover ${Subtitle} {
							border-bottom-color:#1b83a4;
						}
				`;
		}
	}}
`;

const RegionName = styled.div`
	color:#838383;
	font-weight:500;
	font-size:1.66rem;
`;

const Superscript = styled.span`
	vertical-align:super;
	font-size:0.6em;
`;

/*
 *
 */
export default function RegionList ({ regions, type='live' }) {

	const intl = useIntl();
	const lang = intl.locale;

	return (
		<Root>
			{regions.map(region => (
				<RegionItem key={region.id}>
					<RegionLink type={type} as={Link} to={`${lang !== DEFAULT_LANG ? `/${lang}` : ''}/list/${region.id}`}>
						<RegionName>
							<FormattedMessage
								id={`region.${region.id}`}
								values={{
									superscript: (...chunks) => (
										<Superscript>{chunks}</Superscript>
									)
								}}
							/>
						</RegionName>
						<Subtitle>
							{intl.formatMessage({ id: `component.region_list.view` })}
						</Subtitle>
					</RegionLink>
				</RegionItem>
			))}
		</Root>
	);
}
