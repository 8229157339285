/*
 *
 */
import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

/*
 *
 */
const Btn = styled.a`
	display:inline-block;

	font-size:1.55rem;

	color:#fff !important;
	background:#1b83a4;
	padding:1.75rem 3.6rem;
	border-radius:.35rem;
	text-decoration:none;
	transition:0.2s;

	&:hover {
		background:#156A86;
	}
`;

/*
 *
 */
export default function Button ({ children, href, to }) {

	return (
		<Btn as={to ? Link : 'a'} target={href ? "_blank" : undefined} href={href || undefined} to={to || undefined} rel="noopener noreferrer">
			<span>{children}</span>
		</Btn>
	);
}